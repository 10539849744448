<template>
<v-container>
  <h1 class="mt-6 mb-4">
    <a v-bind:href="'/'" style="color: #000000 ;text-decoration: none;"> Главная / Гарантия и возврат</a>
  </h1>

  <p><strong>Гарантия и возврат товара</strong><br />
  По Закону &quot;О защите прав потребителей&quot; (ЗЗПП), а также &quot;Правилам продажи товаров дистанционным способом&quot;, утвержденных постановлением Правительства РФ № 612 от 27.09.2007 года, потребитель при покупке товара в магазине получает гарантийный срок, который предусматривает возврат некачественного товара. Этот срок действует 14 дней после покупки, то есть можно осуществить возврат или обмен товара ненадлежащего качества.</p>

  <p>Магазин гарантирует:</p>

  <p>Подлинность всех товаров;<br />
  Сертифицированную продукцию;<br />
  Работу в соответствии с законодательством РФ;<br />
  Гарантийный срок, который составляет 1 год на все товары по ГОСТу;<br />
  Возврат денег за товар, ненадлежащего качества;<br />
  Качественные фотографии, описание, точные параметры товара;<br />
  Предпродажное диагностирование брака и надежную упаковку товара при доставке.<br />
  Условия возврата товара<br />
  Товар с надлежащим качеством можно вернуть в магазин при условии, если сохранены товарный вид и упаковка. Неиспорченный и не имеющий признаков использования товар можно вернуть без проблем, при условии - транспортные расходы, за счет покупателя. Необходимо сообщить о таком намерении по телефону горячей указанным на сайте, менеджеру магазина.</p>

  <p>Товар ненадлежащего качества или имеющий заводские дефекты также можно вернуть без проблем (сообщите нам о проблеме, при первом обнаружении дефекта). Претензия рассматривается в течение 1 - 2 дней, затем принимается решение о возврате денежных средств или обмене дефектного изделия, на другое без дефекта, за наш счет.</p>

  <p>Возврат денежной суммы за уплаченный товар, при обнаружении брака, производится в течение 7 дней с того дня, когда изделие будет получено нами обратно. Средства зачисляются клиенту на счет, с которого производилась оплата товара.</p>

  <p><strong>Какой товар не является бракованным?</strong><br />
  Износ и потертость не сразу заметны. Только специалист выявит источник деформации или нарушение на текстуре, поэтому внешний вид не определяет, подлежит товар возврату или нет.</p>

  <p>Осуществление обмена товара<br />
  При обмене товара покупатель должен учесть, что на упаковке и товаре не должно быть нарушения комплектности и целостности, а также признаков использования. В течение 1 - 2 дней принимается решение о возврате или обмене. Менеджер помогает подобрать другой образец, если нет аналогичного, затем этот товар отправляется в течение 1 - 2 дней после получения нами товара на обмен.</p>

  <p>Покупатели осуществляют возвраты, отправкой через транспортную компанию СДЭК. Деньги переводятся в течение 5 дней с момента, когда магазин получил возврат.</p>

  <p>Когда не действуют гарантии<br />
  Гарантии не действуют в следующих случаях:</p>

  <p>1. По истечении гарантийного срока - 1 год;<br />
  2. При установлении внутренней экспертизой дефектов, выявленных нарушением условия хранения и использования.</p>

  <p>Интернет-магазин fisolini.ru работает напрямую с производителями и старается минимизировать риски покупателей. По любым возникшим вопросам звоните по телефону нашей горячей линии или закажите обратный звонок.</p>

</v-container>
</template>

<script>
 export default {
    metaInfo:{
        title: 'Гарантия и возврат',
        meta: [
          {
            name: 'description',
            content: 'Магазин гарантирует: подлинность всех товаров, сертифицированную продукцию, работу в соответствии с законодательством РФ, гарантийный срок, который составляет 1 год на все товары по ГОСТу',
          },
          {
            name: 'keywords',
            content: 'Ковры, fisolini, фисолини, компания фисолини, ковры fisolini.ru, ковры, ковры из Бельгии, ковры из Ирана, гарантия fisolini'
          }
        ]
    },
 }
</script>
